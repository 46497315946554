import {
    Activity,
    Briefcase,
    Crop,
    Home,
    Layers,
    PhoneCall,
    Server,
    Trello, TrendingUp, Unlock,
    User,
    Users,
    BarChart, FileText, Settings, Phone, List
} from "react-feather";
//import {BsClockFill} from "react-icons/all";
import * as JsSIP from "react-native-jssip";
import CryptoJS from "crypto-js";

const getEnvConfig = () => {

    return process.env;
};
const decrypt=(text)=> {
    let password = "jE2s8rtuHA9q6FRZ"
    if (!text) return ''
    const bytes = CryptoJS.AES.decrypt(text, password);
    return bytes.toString(CryptoJS.enc.Utf8);
}

const userData = ()=>{
    if (!localStorage.getItem('currentuser')) return {};
    return JSON.parse(decrypt(localStorage.getItem('currentuser')));
}

const Backend_Server = getEnvConfig().REACT_APP_API_BASE_URL;
const App_Base_URL = getEnvConfig().REACT_APP_BASE_URL;

let  socket= new JsSIP.WebSocketInterface(userData().domain_name ==='sip-preaconis.skycrm360.io' ? 'wss://' + userData().domain_name + ':5443/wss':'wss://' + userData().domain_name + ':5443/wss');



const siteConfig = {
    App_Base_URL: App_Base_URL,
    is_production: getEnvConfig().IS_PRODUCTION,
    backendServer: Backend_Server,
    siteName: 'Fonitex @',
    siteIcon: 'ion-flash',
    footerText: `Fonitex @ ${new Date().getFullYear()}`,
    enableAnimatedRoute: false,
    apiUrl: getEnvConfig().REACT_APP_API_BASE_URL,
    apiUrlUpload: getEnvConfig().REACT_APP_API_BASE_URL_UPLOAD,
    apiUrlTelco: getEnvConfig().REACT_APP_API_BASE_URL_TELCO,
    fileUploadUrl: getEnvConfig().REACT_APP_API_BASE_URL_UPLOAD + '/uploadFile',
    tz: 'Europe/Paris',
    agent_options: {
        call_timeout: 30,
        max_no_answer: 0,
        wrap_up_time: 2,
        reject_delay_time: 2,
        busy_delay_time: 2,
        no_answer_delay_time: 20
    },
    socketBaseUrl: getEnvConfig().REACT_APP_SOCKET_URL,

    VerticalHeader: {
        VerticalMenuSuperAdmin: [
            {
                id: 'accounts',
                title: 'account',
                icon: <Users size={20}/>,
                navLink: '/accounts',
                externalLink: false,
                newTab: false
            },
            {
                id: 'Administration',
                title: 'Administration',
                icon: <Server size={20}/>,
                navLink: '/administration',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'Server',
                        title: 'server',
                        icon: <Server size={20}/>,
                        externalLink: false,
                        navLink: '/administration/servers',
                        newTab: false
                    },
                    {
                        id: 'acl',
                        title: 'acl',
                        icon: <Phone size={20}/>,
                        externalLink: false,
                        navLink: '/administration/acls',
                        newTab: false,
                    },
                    {
                        id: 'domain',
                        title: 'domain',
                        icon: <Phone size={20}/>,
                        externalLink: false,
                        navLink: '/administration/domains',
                        newTab: false,
                    }
                ]
            }
        ],
        VerticalMenuAdmin: [
            // {
            //     id: 'home',
            //     title: 'home',
            //     icon: <Home size={20}/>,
            //     navLink: '/home',
            //     externalLink: false,
            //     newTab: false
            // },
            {
                id: 'Real Time',
                title: 'real-time',
                icon: <Trello size={20}/>,
                navLink: '/real-time/dashboard',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'dashboard',
                        title: 'dashboard',
                        icon: <Trello size={20}/>,
                        navLink: '/real-time/dashboard',
                        externalLink: false,
                        newTab: false
                    },
                    // {
                    //     id: 'livecalls',
                    //     title: 'live-calls',
                    //     icon: <TrendingUp size={20}/>,
                    //     navLink: '/real-time/livecalls',
                    //     externalLink: false,
                    //     newTab: false
                    // },
                    {
                        id: 'reminder',
                        title: 'reminder',
                        icon: <Trello size={20}/>,
                        navLink: '/real-time/reminder',
                        externalLink: false,
                        newTab: false
                    }]

            },
            {
                id: 'User Manager',
                title: 'user-manager',
                icon: <User size={20}/>,
                navLink: '/user-manager/users',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'Roles',
                        title: 'roles',
                        icon: <Briefcase size={20}/>,
                        navLink: '/user-manager/roles/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'Users',
                        title: 'users',
                        icon: <User size={20}/>,
                        navLink: '/user-manager/users/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'Agents',
                        title: 'agents',
                        icon: <Users size={20}/>,
                        navLink: '/user-manager/agents/',
                        externalLink: false,
                        newTab: false,
                    }
                ]
            },
            {
                id: 'Management',
                title: 'management',
                icon: <Layers size={20}/>,
                navLink: '/management',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'tempaltes',
                        title: 'template',
                        icon: <FileText size={20}/>,
                        navLink: '/management/templates/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'campaigns',
                        title: 'campaigns',
                        icon: <Briefcase size={20}/>,
                        navLink: '/management/campaigns/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'callfiles-history',
                        title: 'leads',
                        icon: <PhoneCall size={20}/>,
                        navLink: '/management/CallFilesHistory/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'callfiles-history-export',
                        title: 'export-leads',
                        icon: <FileText size={20}/>,
                        navLink: '/management/CallFilesHistoryExport/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'hooper',
                        title: 'hooper',
                        icon: <List size={20}/>,
                        navLink: '/management/hooper',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'callBlunding',
                        title: 'call-blending',
                        icon: <Unlock size={20}/>,
                        navLink: '/management/callBlunding/',
                        externalLink: false,
                        newTab: false
                    },
                ]
            },
            {
                id: 'configuration',
                title: 'config',
                icon: <Settings size={20}/>,
                navLink: '/configuration',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'didsgroups',
                        title: 'dids-group',
                        icon: <PhoneCall size={20}/>,
                        navLink: '/configuration/didsgroups/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'dialplan',
                        title: 'dial-plan',
                        icon: <Activity size={20}/>,
                        navLink: '/configuration/dialplan/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'truncks',
                        title: 'trunk',
                        icon: <Activity size={20}/>,
                        navLink: '/configuration/truncks/',
                        externalLink: false,
                        newTab: false
                    }
                ]
            },


            // {
            //     id: 'meetings',
            //     title: 'Meetings',
            //     icon: <Briefcase size={20}/>,
            //     navLink: '/meetings',
            //     externalLink: false,
            //     newTab: false
            // },

            {
                id: 'cdrs',
                title: 'cdr',
                icon: <PhoneCall size={20}/>,
                navLink: '/cdrs',
                externalLink: false,
                newTab: false
            },
            {
                id: 'Reporting',
                title: 'reporting',
                icon: <BarChart size={20}/>,
                navLink: '/reporting/agent-call-reports',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: ' Agent Call Reports',
                        title: 'agent-call-reports',
                        icon: <Briefcase size={20}/>,
                        navLink: '/reporting/agent-call-reports/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: 'Agent Details Reports',
                        title: 'agent-details-reports',
                        icon: <User size={20}/>,
                        navLink: '/reporting/agent-details-reports/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: ' List Leads Reports',
                        title: 'list-leads-reports',
                        icon: <Users size={20}/>,
                        navLink: '/reporting/list-call-file-reports/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: ' Pause Status Reports',
                        title: 'pause-status-reports',
                        icon: <Users size={20}/>,
                        navLink: '/reporting/pause-status-reports/',
                        externalLink: false,
                        newTab: false,
                    },
                    {
                        id: ' VMD Reports',
                        title: 'vmd-reports',
                        icon: <Users size={20}/>,
                        navLink: '/reporting/vmd-reports/',
                        externalLink: false,
                        newTab: false,
                    }
                ]
            },

        ],
        VerticalMenuAdminBrute: [
            {
                id: 'Campaigns',
                title: 'campaigns',
                icon: <Layers size={20}/>,
                navLink: '/campaigns/',
                externalLink: false,
                newTab: false
            },
            {
                id: 'configuration',
                title: 'config',
                icon: <Settings size={20}/>,
                navLink: '/config',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'callBlunding',
                        title: 'call-blending',
                        icon: <Layers size={20}/>,
                        navLink: '/config/callBlunding/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'didsgroups',
                        title: 'dids-group',
                        icon: <PhoneCall size={20}/>,
                        navLink: '/config/didsgroups/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'truncks',
                        title: 'trunk',
                        icon: <Activity size={20}/>,
                        navLink: '/config/truncks/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'templates',
                        title: 'template',
                        icon: <FileText size={20}/>,
                        navLink: '/config/templates/',
                        externalLink: false,
                        newTab: false
                    },
                    {
                        id: 'Hoopers',
                        title: 'hooper',
                        icon: <Activity size={20}/>,
                        navLink: '/config/hooper/',
                        externalLink: false,
                        newTab: false
                    },
                ]
            },
            {
                id: 'cdrs',
                title: 'cdrs',
                icon: <Activity size={20}/>,
                navLink: '/cdrs/',
                externalLink: false,
                newTab: false
            },
            {
                id: 'Reporting',
                title: 'reporting',
                icon: <Settings size={20}/>,
                navLink: '/reporting',
                externalLink: false,
                newTab: false,
                children: [
                    {
                        id: 'cdr-reporting',
                        title: 'cdr-reporting',
                        icon: <Activity size={20}/>,
                        navLink: '/reporting/cdrs',
                        externalLink: false,
                        newTab: false
                    }
                ]
            }
        ],
        VerticalMenuAgent: [
            {
                id: 'home',
                title: 'home',
                icon: <Activity size={20}/>,
                navLink: '/agent_dashboard/',
                externalLink: false,
                newTab: false
            },
            {
                id: 'reminders',
                title: 'reminders',
                icon: <Activity size={20}/>,
                navLink: '/agent_calendar/',
                externalLink: false,
                newTab: false
            },
            {
                id: 'call-history',
                title: 'call-history',
                icon: <Activity size={20}/>,
                navLink: '/agent_history',
                externalLink: false,
                newTab: false
            }
        ],
        VerticalMenuSales: [
            {
                id: 'Dashboard',
                title: 'dashboard',
                icon: <Activity size={20}/>,
                navLink: '/sales_dashboard',
                externalLink: false,
                newTab: false
            }
        ],

    },
    columns: [
        "context",
        "callid",
        "calldirection",
        "callstatus",
        "sipfromuri_callcenter",
        "sipfromtag_callcenter",
        "siptouri_callcenter",
        "sipToTag_CallCenter",
        "hangupcause",
        "start_time",
        "answertime",
        "end_time",
        "durationsec",
        "durationmsec",
        "privacy",
        "failuresipcode",
        "failuresipreasonphrase",
        "callID",
        "agent",
        "campaignId",
        "eventName",
        "memberUUID",
        "queueJoinedTime",
        "queue",
        "memberSessionUUID",
        "sip_code",
        "sip_reason",
        "src_user",
        "dst_user"
    ],
    columnsCdr: {
        "context" : "Context",
        "callid" : "Call ID",
        "calldirection" : "Call Direction",
        "callstatus" : "Call Status",
        "sipfromuri_callcenter" : "SIP FROM URI CC",
        "sipfromtag_callcenter" : "SIP FROM TAG CC",
        "siptouri_callcenter" : "SIP TO URI CC",
        "sipToTag_CallCenter" : "SIP TO TAG CC",
        "hangupcause" : "Hangup Cause",
        "start_time" : "Start Time",
        "answertime" : "Answer Time",
        "end_time" : "End Time",
        "durationsec" : "Duration (sec)",
        "durationmsec" : "Duration (millisec)",
        "privacy" : "Privacy",
        "failuresipcode" : "Failure sip code",
        "failuresipreasonphrase" : "Failure sip reason phrase",
        "callID" : "Call ID",
        "agent" : "agent",
        "campaignId" : "campaignId",
        "eventName" : "Event Name",
        "memberUUID" : "member UUID",
        "queueJoinedTime" : "Queue Joined Time",
        "queue" : "Queue",
        "memberSessionUUID" : "Member Session UUID",
        "sip_code" : "SIP CODE",
        "sip_reason" : "SIP REASON",
        "src_user" : "Source",
        "dst_user" : "Destination"
    },
    columnsCallFile: [
        "first_name",
        "last_name",
        "call_status",
        "note",
        "country_code",
        "email",
        "postal_code",
        "province",
        "city",
        "state",
        "address1",
        "title",
        "phone_number",
        "middle_initial",
        "comments"
    ],
    callCenter_socket: getEnvConfig().REACT_APP_CALLCENTER_SOCKET,
    pcConfig : {
        'iceServers': [
            {'urls': ['stun:stun.l.google.com:19302']}
        ]
    },
    domainName: userData()?.sip_device?.domain,
    optionsAnswer : {
        'mediaConstraints': {'audio': true},
        RTCConstraints: {"optional": [{'DtlsSrtpKeyAgreement': 'true'}]},
        pcConfig: {
            'iceServers': [
                {'urls': ['stun:stun.l.google.com:19302']}
            ]
        },
    },
    configuration : {
        sockets: [socket],
        uri: 'sip:' + userData()?.sip_device?.username + '@' + userData()?.sip_device?.domain,
        password: userData()?.sip_device?.password,
        connection_recovery_min_interval: 2,
        connection_recovery_max_interval:60,
        register: true
    },
    acapelaLanguages : [
        {value: 'French', label : "French"},
        {value: 'Arabic', label : "Arabic"},
        {value: 'English-UK', label : "English-UK"},
        {value: 'English-US', label : "English-US"},
    ],
    acapelaConfig : {
        "French" : {
            female : [{value: 'Manon', label : "Manon"},{value: 'Alice', label : "Alice"}],
            male : [{value: 'Antoine', label : "Antoine"},{value: 'Bruno', label : "Bruno"}]
        },
        "Arabic" : {
            male : [{value: 'Mehdi', label : "Mehdi"},{value: 'Nizar', label : "Nizar"}],
            female : [{value: 'Leila', label : "Leila"},{value: 'Salma', label : "Salma"}]
        },
        "English-UK" : {
            male :[{value: 'Graham', label : "Graham"},{value: 'Peter', label : "Peter"}],
            female : [{value: 'Lucy', label : "Lucy"},{value: 'Rachel', label : "Rachel"}],
        },
        "English-US" : {
            male : [{value: 'Darius', label : "Darius"},{value: 'Rod', label : "Rod"}],
            female : [{value: 'Laura', label : "Laura"},{value: 'Tracy', label : "Tracy"}]
        }
    },
}

export const apiUrl = siteConfig.apiUrl;
export const apiUrlUpload = siteConfig.apiUrlUpload;
export const fileUploadUrl = Backend_Server + '/uploadFile';
export const apiImageUrl = siteConfig.apiUrlUpload + '/file/thumb/full/';
export const agent_options = siteConfig.agent_options;
export const tz = siteConfig.tz;
export const pcConfig = siteConfig.pcConfig;
export const optionsAnswer = siteConfig.optionsAnswer;
export const configuration = siteConfig.configuration;
export const acapelaLanguages = siteConfig.acapelaLanguages;
export const acapelaConfig = siteConfig.acapelaConfig;

export default siteConfig;
